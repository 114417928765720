import { SequenceMember } from './sequence-member';

export class SequenceConnection {
    destination: SequenceMember;
    source: SequenceMember;

    dotted: boolean = false;
    action: string;

    public get title(): string {
        if (this.destination == null || this.source == null)
            return 'Undefined Connection';
        return this.source.name + ' to ' + this.destination.name;
    }


    constructor() {
    }
}
