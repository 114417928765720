import { Component, OnInit } from '@angular/core';
import { CodeService } from '../../services/code.service';

@Component({
  selector: 'app-diagram',
  templateUrl: './diagram.component.html',
  styleUrls: ['./diagram.component.css']
})
export class DiagramComponent implements OnInit {

  urlParameter: string;
  constructor(private codeService: CodeService) { }

  ngOnInit(): void {
    this.codeService.urlParameter.subscribe(c => {
      this.urlParameter = c;
    });
  }

}
