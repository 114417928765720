import { Component, OnInit } from '@angular/core';
import { CodeService } from '../../services/code.service';

@Component({
  selector: 'app-code',
  templateUrl: './code.component.html',
  styleUrls: ['./code.component.css']
})
export class CodeComponent implements OnInit {
  code: string;

  constructor(private codeService: CodeService) { }

  ngOnInit(): void {
    this.codeService.code.subscribe(c => {
      this.code = c;
    });
  }

}
