<button (click)="addMember()" mat-raised-button>Add Member</button>
<button (click)="addConnection()" mat-raised-button>Add Connection</button>
<button (click)="redraw()" color="primary" mat-raised-button>Redraw</button>

<br /><br />
<h2>Members</h2>
<mat-accordion class="headers-align" multi>

  <mat-expansion-panel *ngFor="let member of members; let i = index">
    <mat-expansion-panel-header>
      {{member.name}}
    </mat-expansion-panel-header>

    <mat-form-field>
      <mat-label>Name</mat-label>
      <input name="name" matInput placeholder="Name" [(ngModel)]="member.name">
    </mat-form-field>

    <mat-form-field>
      <mat-label>Type</mat-label>
      <mat-select name="type" [(ngModel)]="member.type">
        <mat-option *ngFor="let type of memberTypes" [value]="type">
          {{type}}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-divider></mat-divider>

    <mat-form-field>
      <mat-label>Color</mat-label>
      <input name="name" matInput placeholder="Color" [(ngModel)]="member.color">
      
    </mat-form-field>
    <p-colorPicker [(ngModel)]="member.color"></p-colorPicker>
   
    

<br /><br /><br /><br /><br /><br /><br />

    <mat-action-row>
      <button mat-button color="basic" (click)="moveMember(i, -1)" *ngIf="i > 0">Move Up</button>
      <button mat-button color="basic" (click)="moveMember(i, 1)" *ngIf="i < members.length-1">Move Down</button>
      <button mat-button color="warn" (click)="removeMember(i)">Remove</button>
    </mat-action-row>
  </mat-expansion-panel>

</mat-accordion>

<br />

<h2>Connections</h2>

<mat-accordion class="headers-align" multi>
  <mat-expansion-panel *ngFor="let connection of connections; let i = index">
    <mat-expansion-panel-header>
      {{ connection.title }}
    </mat-expansion-panel-header>

    <!-- Destination -->
    <mat-form-field>
      <mat-label>Source</mat-label>
      <mat-select name="type" [(ngModel)]="connection.source">
        <mat-option *ngFor="let src of members" [value]="src">
          {{src.name}}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <!-- Destination -->
    <mat-form-field>
      <mat-label>Destination</mat-label>
      <mat-select name="type" [(ngModel)]="connection.destination">
        <mat-option *ngFor="let dest of members" [value]="dest">
          {{dest.name}}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-divider></mat-divider>
    <mat-checkbox color="primary" [(ngModel)]="connection.dotted">Dotted line</mat-checkbox>

    <br />

    <mat-form-field>
      <mat-label>Action</mat-label>
      <input name="name" matInput placeholder="Action" [(ngModel)]="connection.action">
    </mat-form-field>

    <mat-action-row>
      <button mat-button color="basic" (click)="moveConnection(i, -1)" *ngIf="i > 0">Move Up</button>
      <button mat-button color="basic" (click)="moveConnection(i, 1)" *ngIf="i < connections.length-1">Move
        Down</button>
      <button mat-button color="warn" (click)="removeConnection(i)">Remove</button>
    </mat-action-row>

  </mat-expansion-panel>
</mat-accordion>
