import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatListModule } from '@angular/material/list';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatCheckboxModule } from '@angular/material/checkbox';

import { ColorPickerModule } from 'primeng/colorpicker';

import { DiagramComponent } from './components/diagram/diagram.component';
import { CodeComponent } from './components/code/code.component';
import { EditorComponent } from './components/editor/editor.component';
import { SequenceComponent } from './components/sequence/sequence.component';

import { CodeService } from './services/code.service';

@NgModule({
  declarations: [
    AppComponent,
    EditorComponent,
    SequenceComponent,
    DiagramComponent,
    CodeComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    BrowserAnimationsModule,
    DragDropModule,
    MatTabsModule,
    MatToolbarModule,
    MatListModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatExpansionModule,
    MatIconModule,
    MatCheckboxModule,
    ColorPickerModule,
  ],
  providers: [CodeService],
  bootstrap: [AppComponent],
})
export class AppModule {}
