import { Component, OnInit } from '@angular/core';
import { SequenceMember } from '../../models/sequence-member';
import { CodeService } from '../../services/code.service';
import { SequenceConnection } from 'src/app/models/sequence-connection';

@Component({
  selector: 'app-sequence',
  templateUrl: './sequence.component.html',
  styleUrls: ['./sequence.component.css'],
})
export class SequenceComponent implements OnInit {
  members: SequenceMember[];
  connections: SequenceConnection[] = [];

  selectedMember: SequenceMember;
  hexCode: string;

  memberTypes: string[];

  addMember() {
    this.members.push(new SequenceMember());
  }

  constructor(private codeService: CodeService) {}

  ngOnInit(): void {
    this.members = [new SequenceMember(), new SequenceMember()];
    this.memberTypes = [
      'actor',
      'boundary',
      'control',
      'entity',
      'database',
      'collections',
      'participant',
    ];
  }

  redraw() {
    let code = '@startuml\n\n';
    this.members.forEach((m) => {
      code += m.type + ' "' + m.name + '"';
      if (m.color != null) code += ' ' + m.color;

      code += '\n';
    });

    code += '\n';
    this.connections.forEach((c) => {
      code += '"' + c.source.name + '"';
      if (c.dotted) {
        code += ' --> ';
      } else {
        code += ' -> ';
      }
      code += '"' + c.destination.name + '"';

      if (c.action != '') code += ': ' + c.action;

      code += '\n';
    });

    code += '\n@enduml';
    this.codeService.setCode(code);
  }

  moveMember(index: number, slots: number) {
    this.members.splice(slots, 0, this.members.splice(index, 1)[0]);
  }

  moveConnection(index: number, slots: number) {
    this.connections.splice(slots, 0, this.connections.splice(index, 1)[0]);
  }

  removeMember(index: number) {
    this.members.splice(index, 1);
  }

  addConnection() {
    this.connections.push(new SequenceConnection());
  }

  removeConnection(index: number) {
    this.connections.splice(index, 1);
  }
}
