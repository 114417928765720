<mat-toolbar color="primary">
  <span> PlantUML Designer </span>
  <span class="example-fill-remaining-space"></span>
</mat-toolbar>

<nav mat-tab-nav-bar>
  <a mat-tab-link *ngFor="let link of navLinks" [routerLink]="link.link" routerLinkActive #rla="routerLinkActive"
    [active]="rla.isActive">
    {{link.label}}
  </a>
</nav>
<br />
<div class="content">
  <div class="row">
    <div class="col-md-6">
      <router-outlet></router-outlet>
    </div>
    <div class="col-md-6">
      <p>
        <app-diagram></app-diagram>
      </p>
      <p>
        <app-code></app-code>
      </p>
    </div>
  </div>
</div>
