import { Injectable, Output, EventEmitter } from '@angular/core';


@Injectable({
  providedIn: 'root'
})
export class CodeService {

  @Output() urlParameter: EventEmitter<string> = new EventEmitter();
  @Output() code: EventEmitter<string> = new EventEmitter();

  setCode(code: string) {

    var s = unescape(encodeURI(code));
    var o = "~h" + this.hexEncode(s);


    this.urlParameter.emit(o);
    this.code.emit(code);
  }


  hexEncode = function (s) {
    var hex, i;

    var result = "";
    for (i = 0; i < s.length; i++) {
      hex = s.charCodeAt(i).toString(16);
      if (hex == "a")
        hex = "0d0a";
      result += (hex).slice(-4);
    }

    return result
  }
}
